import { useEffect, useState } from "react";
import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { useForm } from "../../hooks";
import {
  useGetEstudianteQuery,
  useGetPeriodosUnidadEstudianteQuery,
  useGetTipoCertificadosQuery,
} from "../../store/api/estudiantesApi";

import { AdminAcciones } from ".";
import { EstudiantesAcciones } from "../../Estudiantes/components";

import { LoadingData } from "../../ui/components/LoadingData";
import { useSelector } from "react-redux";

export const BusquedaEstudiante = ({
  identificacionEstudiante,
  tipoUsuario = "ESTUDIANTE",
}) => {
  const [periodoUnidad, setPeriodoUnidad] = useState("Periodo");
  // Extraer la data del Store del usuario
  const { email } = useSelector((state) => state.auth);

  // Inputs que se utilizaran en el formulario
  const inputsForm = {
    tipoCertificado: "",
    programaEstudiante: "",
    periodoPrograma: {},
  };

  // Mensajes de validacion sobre los campos indicados
  const formValidations = {
    programaEstudiante: [
      (value) => value.length >= 1,
      "Se debe seleecionar un tipo de programa.",
    ],
    tipoCertificado: [
      (value) => JSON.stringify(value).length >= 1,
      "Se debe seleccionar un tipo de certificado.",
    ],
    periodoPrograma: [
      (value) => value?.cod_periodo,
      "Se debe seleccionar un periodo de programa.",
    ],
  };

  // Se desestructuran los inputs y sus validaciones
  const {
    formState,
    tipoCertificado,
    programaEstudiante,
    periodoPrograma,
    handleInputChange,
    handleResetForm,
    isFormValid,
    programaEstudianteValid,
    periodoProgramaValid,
    tipoCertificadoValid,
  } = useForm(inputsForm, formValidations);

  // Consultar servicio de informacion del estudiante
  const { data: estudiante, isFetching: cargandoEstudiante } =
    useGetEstudianteQuery(identificacionEstudiante);

  // Consultar los tipos de certificados disponibles
  const [datosServicioCertificados, setDatosServicioCertificados] = useState();
  const { data: tiposCertificados } = useGetTipoCertificadosQuery(
    datosServicioCertificados
  );

  // Consultar servicio para extraer los periodos activos del programa seleccionado
  const { data: listaPeriodos, isFetching: cargandoPeriodos } =
    useGetPeriodosUnidadEstudianteQuery({
      identificacion: identificacionEstudiante,
      terceroId: estudiante?.informacionEstudiante?.id_tercero,
      codUnidad: programaEstudiante,
      tipCertificado: tipoCertificado?.tipo_producto,
    });

  // Al cambiar el numero de documento del estudiante el formulario se resetea totalmente
  useEffect(() => {
    handleResetForm();
  }, [identificacionEstudiante]);

  useEffect(() => {
    if (!estudiante) return;

    const programa = estudiante?.programasEstudiante.find(
      (p) => p.cod_unidad === programaEstudiante
    );

    if (!programa) return; //colocar una alerta de error ya que no se encontró un programa

    if (
      programa.nom_unidad.toLowerCase().includes("ingles") ||
      programa.nom_unidad.toLowerCase().includes("inglés")
    ) {
      setPeriodoUnidad("Nivel");
    } else {
      setPeriodoUnidad("Periodo");
    }

    setDatosServicioCertificados({
      email,
      identificacionEstudiante: identificacionEstudiante,
      est_alumno: programa.est_alumno,
      nom_unidad: programa.nom_unidad,
      cod_unidad: programa.cod_unidad,
      id_tercero: programa.id_tercero,
    });
  }, [programaEstudiante]);

  if (cargandoEstudiante || cargandoPeriodos) {
    return <LoadingData />;
  }

  if (!estudiante) {
    return <Alert severity="warning">Sin información del estudiante</Alert>;
  }

  const MensajeTipoCertificadoPeriodo = () => {
    return (
      <Grid item xs={12}>
        <Alert severity="warning" sx={{ mt: 3 }}>
          Tipo de certificado no disponible para generar,{" "}
          <b>ERROR: {periodoPrograma?.mensaje_es_valido}</b>
        </Alert>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          label="Nombres del estudiante"
          type="text"
          placeholder="Nombres del estudiante"
          fullWidth
          name="nombresEstudiante"
          value={estudiante?.informacionEstudiante?.nom_largo}
          disabled={true}
          onChange={handleInputChange}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <FormControl fullWidth error={!!programaEstudianteValid}>
          <InputLabel id="programaEstudiante">Programa</InputLabel>
          <Select
            labelId="programaEstudiante"
            id="programa-simple-select"
            name="programaEstudiante"
            value={programaEstudiante}
            label="programaEstudiante"
            onChange={handleInputChange}
          >
            <MenuItem value="" disabled={true}>
              Seleccione una opción
            </MenuItem>

            {estudiante?.programasEstudiante?.map((programa) => {
              let estAlumnoString;

              switch (programa.est_alumno) {
                case "0":
                  estAlumnoString = "- INACTIVO";
                  break;
                case "1":
                  estAlumnoString = "- ACTIVO";
                  break;
                case "2":
                  estAlumnoString = "- EGRESADO";
                  break;
                case "3":
                  estAlumnoString = "- GRADUADO";
                  break;
                case "4":
                  estAlumnoString = "- TRASLADO";
                  break;
                default:
                  estAlumnoString = "";
              }

              return (
                <MenuItem
                  key={programa.id_alum_programa}
                  value={programa.cod_unidad}
                >
                  {programa.cod_unidad} - {programa.nom_unidad}{" "}
                  {estAlumnoString}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{programaEstudianteValid}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        {programaEstudiante && (
          <FormControl fullWidth error={!!tipoCertificadoValid}>
            <InputLabel id="tipoCertificado">Tipo certificado</InputLabel>
            <Select
              labelId="tipoCertificado"
              id="certificado-simple-select"
              name="tipoCertificado"
              value={tipoCertificado}
              label="tipoCertificado"
              onChange={handleInputChange}
              defaultValue=""
              required
            >
              <MenuItem value="" disabled={true} selected>
                Seleccione una opción
              </MenuItem>
              {tiposCertificados?.map((tipo) => (
                <MenuItem value={tipo} key={tipo.producto}>
                  {tipo.descripcion}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{tipoCertificadoValid}</FormHelperText>
          </FormControl>
        )}
      </Grid>

      {
        // Si el campo programa estudiante y tipo de certificado estan completos entonces se muestra la información del periodo
        programaEstudiante && tipoCertificado && (
          <>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <FormControl fullWidth error={!!periodoProgramaValid}>
                <InputLabel id="periodoPrograma">{periodoUnidad}</InputLabel>
                <Select
                  labelId="periodoPrograma"
                  id="periodo-simple-select"
                  name="periodoPrograma"
                  value={periodoPrograma}
                  label="periodoPrograma"
                  onChange={handleInputChange}
                  defaultValue=""
                  required={true}
                >
                  <MenuItem value="" disabled={true} selected>
                    Seleccione una opción
                  </MenuItem>
                  {listaPeriodos?.periodos?.map((periodo) => (
                    <MenuItem key={periodo.cod_periodo} value={periodo}>
                      {periodo.cod_periodo}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{periodoProgramaValid}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <TextField
                label="Valor del certificado"
                type="text"
                placeholder="Valor del certificado"
                fullWidth
                name="valorCertificado"
                value={"$ " + tipoCertificado.valor_unidad}
                disabled={true}
              />
            </Grid>
          </>
        )
      }

      {
        // Se valida si el tipo de certificado es valido para generar , en caso que no sea valido se muestra el error
        isFormValid && periodoPrograma?.es_valido === "false" ? (
          <MensajeTipoCertificadoPeriodo />
        ) : // Se valida que tipo de acción tendra el certificado (Pagar o Generar PDF)
        isFormValid && tipoUsuario === "ADMIN" ? (
          <AdminAcciones
            camposFormularioBusqueda={formState}
            identificacionEstudiante={identificacionEstudiante}
            id_tercero={estudiante?.informacionEstudiante?.id_tercero}
            nombres={estudiante?.informacionEstudiante?.nombres}
            apellidos={estudiante?.informacionEstudiante?.apelidos}
            email={estudiante?.informacionEstudiante?.email}
            telefono={estudiante?.informacionEstudiante?.telefono}
            movil={estudiante?.informacionEstudiante?.movil}
            direccion={estudiante?.informacionEstudiante?.direccion}
            tipoCertificado={tipoCertificado}
          />
        ) : // Se valida que tipo de acción tendra el certificado (Pagar o Generar PDF)
        isFormValid && tipoUsuario === "ESTUDIANTE" ? (
          <EstudiantesAcciones
            camposFormularioBusqueda={formState}
            identificacionEstudiante={identificacionEstudiante}
            id_tercero={estudiante?.informacionEstudiante?.id_tercero}
            tipoCertificado={tipoCertificado}
          />
        ) : (
          isFormValid &&
          tipoUsuario === "ADMINPANTALLAS" && (
            <AdminAcciones
              camposFormularioBusqueda={formState}
              identificacionEstudiante={identificacionEstudiante}
              id_tercero={estudiante?.informacionEstudiante?.id_tercero}
              nombres={estudiante?.informacionEstudiante?.nombres}
              apellidos={estudiante?.informacionEstudiante?.apelidos}
              email={estudiante?.informacionEstudiante?.email}
              telefono={estudiante?.informacionEstudiante?.telefono}
              movil={estudiante?.informacionEstudiante?.movil}
              direccion={estudiante?.informacionEstudiante?.direccion}
              tipoCertificado={tipoCertificado}
              botonesAccion="enviar"
            />
          )
        )
      }
    </Grid>
  );
};
