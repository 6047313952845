import { Grid } from "@mui/material";
import React from "react";
import { DashboardLayout } from "../../dashboard/layout/DashboardLayout";
import Swal from "sweetalert2";

export const PagosPage = () => {
  return (
    // // Mostrar alerta antes de redirigir
    // Swal.fire({
    //   title: "Genera tus certificados en 360",
    //   text: "Hemos mejorado el proceso. Ahora puedes pagar tus certificados de forma rápida y sencilla desde la plataforma 360",
    //   icon: "success",
    //   confirmButtonText: "Ir a 360",
    //   allowOutsideClick: true, // ✅ Permite hacer clic fuera
    //   allowEscapeKey: true, // ✅ Permite cerrar con Esc
    // }).then(() => {
    //   // ✅ Redirige siempre, sin importar cómo se cierre la alerta
    //   window.location.href = "https://360.cunapp.pro";
    // }),
    <DashboardLayout>
      <Grid
        container
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          pt: "56.25%",
          mt: 1,
        }}
      >
        <iframe
          src="https://botondepago.cun.edu.co:8443/BotonPago/faces/index.xhtml"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
          frameBorder="0"
          id="iframe_pagos"
          title="iframe_pagos"
        ></iframe>
      </Grid>
    </DashboardLayout>
  );
};
