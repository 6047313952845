import { useState } from "react";
import { Grid, IconButton, InputBase, Paper, Typography } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { BusquedaEstudiante } from "../components";
import { DashboardLayout } from "../../dashboard/layout/DashboardLayout";
import Swal from "sweetalert2";

export const AdminCertificadosPage = () => {
  const [numeroIdentificacion, setNumeroIdentificacion] = useState();

  const onSubmit = (event) => {
    event.preventDefault();
    setNumeroIdentificacion(event.target.numeroIdentificacion.value);
  };

  return (
    // // Mostrar alerta antes de redirigir
    // Swal.fire({
    //   title: "Genera tus certificados en 360",
    //   text: "Hemos mejorado el proceso. Ahora puedes generar tus certificados de forma rápida y sencilla desde la plataforma 360",
    //   icon: "success",
    //   confirmButtonText: "Ir a 360",
    //   allowOutsideClick: true, // ✅ Permite hacer clic fuera
    //   allowEscapeKey: true, // ✅ Permite cerrar con Esc
    // }).then(() => {
    //   // ✅ Redirige siempre, sin importar cómo se cierre la alerta
    //   window.location.href = "https://360.cunapp.pro";
    // }),
    <DashboardLayout>
      <form onSubmit={onSubmit}>
        <Grid container sx={{ p: 5 }}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Generar certificados
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography>
              Selecciona los datos del estudiante y el tipo de certificado
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Paper
              component="div"
              sx={{
                p: "3px 5px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="número identificación"
                fullWidth
                inputProps={{ "aria-label": "search google maps" }}
                label="Número de identificación del estudiante"
                type="number"
                name="numeroIdentificacion"
                autoComplete="off"
                autoFocus
              />
              <IconButton
                type="submit"
                sx={{ p: "10px" }}
                aria-label="search"
                color="primary"
              >
                <SearchOutlined />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </form>

      <Grid sx={{ pl: 5, pr: 5 }}>
        {numeroIdentificacion && (
          <BusquedaEstudiante
            identificacionEstudiante={numeroIdentificacion}
            tipoUsuario="ADMIN"
          />
        )}
      </Grid>
    </DashboardLayout>
  );
};
