import React, { useState } from "react";
import Link from "@mui/material/Link";
import Swal from "sweetalert2";

import {
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { SearchOutlined } from "@mui/icons-material";

import { DashboardLayout } from "../../dashboard/layout/DashboardLayout";

import { certificadosApi } from "../../api/certificadosApi";
import { LoadingData } from "../../ui/components/LoadingData";

export const AdminCertificadosPagos = () => {
  const [listaSolicitudes, setListaSolicitudes] = useState([]);

  const [status, setStatus] = useState("loaded");

  const consultarListaCertificados = async (identificacion = "") => {
    if (!identificacion) return;

    setStatus("loading");
    await certificadosApi
      .post("getListarSolcitudesCertificados", { identificacion })
      .then((resp) => setListaSolicitudes(resp.data))
      .catch((error) => console.log(error));

    setStatus("loaded");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    consultarListaCertificados(event.target.numeroIdentificacion.value);
  };

  return (
    // // Mostrar alerta antes de redirigir
    // Swal.fire({
    //   title: "Genera tus certificados en 360",
    //   text: "Hemos mejorado el proceso. Ahora puedes pagar tus certificados de forma rápida y sencilla desde la plataforma 360",
    //   icon: "success",
    //   confirmButtonText: "Ir a 360",
    //   allowOutsideClick: true, // ✅ Permite hacer clic fuera
    //   allowEscapeKey: true, // ✅ Permite cerrar con Esc
    // }).then(() => {
    //   // ✅ Redirige siempre, sin importar cómo se cierre la alerta
    //   window.location.href = "https://360.cunapp.pro";
    // }),
    <DashboardLayout>
      <Grid container sx={{ p: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Reporte de certificados solicitados
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography>
            Podras consultar los certificados que se encuentran pagos
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
          <form onSubmit={onSubmit}>
            <Paper
              component="div"
              sx={{
                p: "3px 5px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="número identificación del estudiante"
                fullWidth
                label="Número de identificación del estudiante"
                type="number"
                name="numeroIdentificacion"
                autoComplete="off"
                autoFocus
              />
              <IconButton
                type="submit"
                sx={{ p: "10px" }}
                aria-label="search"
                color="primary"
              >
                <SearchOutlined />
              </IconButton>
            </Paper>
          </form>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          {status === "loading" ? (
            <LoadingData />
          ) : listaSolicitudes.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">FECHA</TableCell>
                    <TableCell align="center">FECHA PAGO</TableCell>
                    <TableCell align="center">DOCUMENTO</TableCell>
                    <TableCell align="center">NOMBRES</TableCell>
                    <TableCell align="center">EMAIL</TableCell>
                    <TableCell align="center">PRODUCTO</TableCell>
                    <TableCell align="center">ORDEN</TableCell>
                    <TableCell align="center">PRODUCTO</TableCell>
                    <TableCell align="center">REFERENCIA</TableCell>
                    <TableCell align="center">VALOR</TableCell>
                    <TableCell align="center">ESTADO</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaSolicitudes.map((row) => (
                    <TableRow
                      key={row.secuencia}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        textAlign: "center",
                      }}
                    >
                      <TableCell>{row.fecha_registro}</TableCell>
                      <TableCell>{row.fecha_estado}</TableCell>
                      <TableCell>{row.documento}</TableCell>
                      <TableCell>
                        {row.primer_nombre} {row.primer_apellido}
                      </TableCell>
                      <TableCell>{row.email_institucional}</TableCell>
                      <TableCell>{row.descripcion_producto}</TableCell>
                      <TableCell>{row.orden}</TableCell>
                      <TableCell>{row.producto}</TableCell>
                      <TableCell>{row.ref_producto}</TableCell>
                      <TableCell>{row.valor}</TableCell>
                      <TableCell>{row.marca_pago}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.marca_pago.toUpperCase() === "PAGADO" ? (
                          <Link
                            variant="body2"
                            href={`${process.env.REACT_APP_API_URL}/certificados/generarCertificadoPago?secuencia=${row.secuencia}&identificacion=${row.documento}`}
                            target="_blank"
                          >
                            <DownloadForOfflineIcon
                              sx={{ color: "primary.main" }}
                            />
                          </Link>
                        ) : (
                          <ProductionQuantityLimitsIcon sx={{ color: "red" }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="warning">
              No hay registros para mostrar, ingrese un número de identificación
              para consultar
            </Alert>
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
