import { useSelector } from "react-redux";
import { Alert, Grid, TextField, Typography } from "@mui/material";

import { DashboardLayout } from "../../dashboard/layout/DashboardLayout";
import { BusquedaEstudiante } from "../../Certificados/components";
import Swal from "sweetalert2";

export const EstudianteCertificadosPage = () => {
  const { numIdentificacion } = useSelector((state) => state.auth);

  return (
    // // Mostrar alerta antes de redirigir
    // Swal.fire({
    //   title: "Genera tus certificados en 360",
    //   text: "Hemos mejorado el proceso. Ahora puedes solicitar tus certificados de forma rápida y sencilla desde la plataforma 360",
    //   icon: "success",
    //   confirmButtonText: "Ir a 360",
    //   allowOutsideClick: true, // ✅ Permite hacer clic fuera
    //   allowEscapeKey: true, // ✅ Permite cerrar con Esc
    // }).then(() => {
    //   // ✅ Redirige siempre, sin importar cómo se cierre la alerta
    //   window.location.href = "https://360.cunapp.pro";
    // }),
    <DashboardLayout>
      <Grid container sx={{ p: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Solicitar certificados
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography>
            Selecciona el tipo de certificado que deseas generar
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          {!numIdentificacion.length ? (
            <Alert severity="warning">
              No hay un número de identificación del estudiante
            </Alert>
          ) : (
            <>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <TextField
                  label="Identificación del estudiante"
                  type="text"
                  placeholder="Identificación del estudiante"
                  fullWidth
                  name="identificacionEstudiante"
                  value={numIdentificacion}
                  disabled={true}
                />
              </Grid>

              <BusquedaEstudiante
                identificacionEstudiante={numIdentificacion}
                tipoUsuario="ESTUDIANTE"
              />
            </>
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
