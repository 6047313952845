import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const estudiantesApi = createApi({
  reducerPath: "estudiantes",

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/estudiantes`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.uid;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getEstudiante: builder.query({
      query: (identificacion) => {
        if (!identificacion) {
          throw new Error(
            "Número de identificación requerido para buscar estudiante"
          );
        }

        return {
          url: "/informacionEstudiante",
          method: "POST",
          body: { identificacion },
        };
      },
    }),

    getPeriodosUnidadEstudiante: builder.query({
      query: ({ identificacion, terceroId, codUnidad, tipCertificado }) => {
        if (!codUnidad) {
          throw new Error("Código unidad requerido buscar periodos");
        }

        if (!tipCertificado) {
          throw new Error("Tipo de certificado requerido buscar periodos");
        }

        return {
          url: "/periodosUnidadEstudiante",
          method: "POST",
          body: { identificacion, terceroId, codUnidad, tipCertificado },
        };
      },
    }),

    getTipoCertificados: builder.query({
      query: ({
        email,
        identificacionEstudiante,
        est_alumno,
        nom_unidad,
        cod_unidad,
        id_tercero,
      }) => {
        return {
          url: `${process.env.REACT_APP_API_URL}/certificados/getListarTipoCertificados`,
          method: "POST",
          body: {
            email,
            identificacionEstudiante,
            est_alumno,
            nom_unidad,
            cod_unidad,
            id_tercero,
          },
        };
      },
    }),
  }),
});

// Action creators are generated for each case reducer function
export const {
  useGetEstudianteQuery,
  useGetPeriodosUnidadEstudianteQuery,
  useGetTipoCertificadosQuery,
} = estudiantesApi;
